<template>
  <main class="main school">
    <div class="school__container">
      <aside class="school__aside">
        <div class="school__card">
          <img
            :alt="school.head_img | image_alt"
            class="school__image"
            :src="school.head_img | image($store.state._env.MEDIA_ENDPOINT)"
          />
          <h1 class="school__title">{{ school.title }}</h1>
          <div class="school__rating" v-if="school.reviews_avg_mark">
            <span class="school__rating-number">{{ school.reviews_avg_mark.toFixed(1) || "-" }}</span>
            <div class="school__rating-stars" v-if="school.reviews_avg_mark">
              <StarIcon
                v-for="i in 5"
                :key="i"
                :class="{ inactive: school.reviews_avg_mark.toFixed(1) < i - 0.5 }"
              />
            </div>
            <span class="school__rating-reviews">
              ( {{ school.reviews.length | plural("оценка", "оценки", "оценок") }} )
            </span>
          </div>
        </div>
        <div class="school__socials" v-if="school.socials.length">
          <span class="school__socials-title">Наши социальные сети:</span>
          <div class="school__socials-list">
            <a
              v-for="(social, i) in school.socials.filter((s) => s.icon)"
              :key="i"
              class="school__socials-item"
              :href="social.value"
              rel="nofollow"
              target="_blank"
            >
              <figure class="icon" v-html="social.icon"></figure>
            </a>
          </div>
        </div>
      </aside>
      <div class="school__main">
        <article class="school__about" v-if="school.about">
          <h2 class="school__about-title">О школе</h2>
          <div class="school__about-text" v-if="school.about">
            <EditorJSComponent :text="school.about" />
          </div>
        </article>
        <div class="school__courses" v-if="school.courses.length">
          <h2 class="school__courses-title">Наши курсы</h2>
          <div class="school__courses-list">
            <CourseComponent
              v-for="(course, i) in school.courses"
              @favorite="addToFavorites(course.id)"
              :course="course"
              :key="i"
              :favorite="isInFavorites(course.id)"
              fixed
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import StarIcon from "components/svg/StarIcon.vue";
import CourseComponent from "components/CourseComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "SchoolsOpenPage",
  data() {
    return {
      favoritesIdList: [],
    };
  },
  computed: {
    school() {
      return this.$store.state.school_page.school;
    },
  },
  methods: {
    addToFavorites(id) {
      const index = this.favoritesIdList.indexOf(id);
      if (index !== -1) {
        this.favoritesIdList.splice(index, 1);
      } else {
        this.favoritesIdList.push(id);
      }
    },
    isInFavorites(id) {
      return this.favoritesIdList.indexOf(id) !== -1;
    },
  },
  components: { EditorJSComponent, CourseComponent, StarIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.school {
  margin-top 150px
  margin-bottom 130px
  display flex
  flex-direction column
  align-items center
  justify-content flex-start
  width 100%
  padding 0 32px
  +below(1120px) {
    margin-top 95px
    margin-bottom 50px
  }
  +below(640px) {
    margin-bottom 0
    padding 0 20px
  }

  &__container {
    display grid
    grid-template-columns 3fr 9fr
    grid-gap 20px
    max-width var(--main_width)
    width 100%
    +below(1220px) {
      grid-template-columns 4fr 8fr
    }
    +below(1120px) {
      grid-template-columns 3.5fr 8.5fr
    }
    +below(926px) {
      grid-template-columns 1fr
    }
    +below(640px) {
      grid-gap 30px
    }
  }

  &__aside {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    width 100%
    gap 20px
    +below(1120px) {
      gap 15px
    }
    +below(926px) {
      flex-direction row
    }
    +below(640px) {
      flex-direction column
    }
  }

  &__card {
    background: var(--white);
    box-shadow: 0 16px 24px var(--black_o06), 0px 2px 6px var(--black_o04), 0px 0px 1px var(--black_o04);
    border-radius: 20px;
    padding 140px 40px 40px
    display flex
    flex-direction column
    align-items center
    justify-content center
    width 100%
    position relative
    +below(1120px) {
      padding 100px 25px 25px
    }
  }

  &__image {
    absolute left top right
    transform: translateY(-50%);
    margin: auto;
    border-radius 100%
    object-fit none
    background var(--gray_divider)
    object-position center
    width 200px
    height 200px
    border 10px solid var(--gray_light)
    +below(1120px) {
      width 150px
      height 150px
    }
  }

  &__title {
    font-weight: bold;
    font-size: 1.5em;
    line-height: 29px;
    text-align: center;
    margin 0 0 15px
    +below(1120px) {
      font-size: 1.375em;
      line-height: 27px;
    }
  }

  &__rating {
    display flex
    justify-content center
    align-items center
    gap 10px
    flex-wrap wrap

    &-number {
      font-weight: bold;
      font-size: 0.875em;
      line-height: 17px;
    }

    &-stars {
      display flex
      align-items center
      gap 5px

      .icon {
        flex-shrink 0
        width 20px
        height 20px

        &.inactive svg {
          svg(var(--gray))
        }

        svg {
          width 100%
          height 100%
          svg(var(--orange))
        }
      }
    }

    &-reviews {
      font-size: 0.875em;
      line-height: 17px;
    }
  }

  &__socials {
    background: var(--white);
    box-shadow: 0 16px 24px var(--black_o06), 0px 2px 6px var(--black_o04), 0px 0px 1px var(--black_o04);
    border-radius: 20px;
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 12px
    padding 25px

    &-title {
      font-weight: 500;
      font-size: 1.125em;
      line-height: 22px;
      text-align: center;
    }

    &-list {
      display flex
      align-items center
      justify-content flex-start
      gap 15px
      +below(1120px) {
        margin auto
      }
      +below(926px) {
        margin-right auto
        margin-left 0
      }
    }

    &-item {
      display flex
      align-items center
      justify-content flex-start

      .icon {
        width 22px
        height 22px

        svg {
          width 100%
          height 100%
          svg(var(--black))
        }
      }
    }
  }

  &__main {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    width 100%
    gap 20px
  }

  &__about {
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 20px
    background: var(--white);
    border-radius: 20px;
    padding 40px
    +below(1120px) {
      padding 25px
      gap 15px
    }

    &-title {
      font-weight: bold;
      font-size: 3em;
      line-height: 58px;
      margin-bottom 0
      +below(1120px) {
        font-size: 1.875em;
        line-height: 36px;
      }
    }

    &-text {
      font-weight: normal;
      font-size: 1.125em;
      line-height: 150%;
      max-width 833px
      +below(1120px) {
        font-size: 1em;
      }
    }
  }

  &__courses {
    background: var(--white);
    border-radius: 20px;
    padding 40px
    gap 30px
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    width 100%
    +below(1120px) {
      padding 20px
      gap 15px
    }
    +below(640px) {
      margin: 0 -20px;
      border-bottom-right-radius 0
      border-bottom-left-radius 0
      width: calc(100% + 40px);
    }

    &-title {
      font-weight: bold;
      font-size: 48px;
      line-height: 58px;
      margin 0
      +below(1120px) {
        font-size: 1.875em;
        line-height: 36px;
      }
    }

    &-list {
      display grid
      grid-template-columns 1fr 1fr 1fr
      grid-gap 20px
      +below(1220px) {
        grid-template-columns 1fr 1fr
      }
      +below(560px) {
        grid-template-columns 1fr
      }
    }
  }
}
</style>
