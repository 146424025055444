<template>
  <Component :is="page" />
</template>

<script>
import SCHOOL_PAGE from "@/graphql/pages/SchoolPage.graphql";
import SchoolsOpenPage from "./page.vue";
import PageNotFound from "@/views/PageNotFound.vue";

export default {
  name: "SchoolsOpenIndex",
  async asyncData({ apollo, store, route, res }) {
    if (!route.params && !route.params.link) {
      res.status(404);
      return;
    }
    await apollo.defaultClient
      .query({
        query: SCHOOL_PAGE,
        variables: {
          link: route.params.link,
        },
      })
      .then(({ data }) => {
        if (!data.school) {
          res.status(404);
        }
        store.state.school_page.school = data.school;
      })
      .catch(() => {});
  },
  computed: {
    page() {
      if (this.$store.state.school_page.school) {
        return SchoolsOpenPage;
      }
      return PageNotFound;
    },
  },
};
</script>
